














































































































import Vue from 'vue'
import {
  PropValidator,
} from 'vue/types/options'
import {
  Lesson, LessonType,
} from '@/types/lesson'
import {
  newLesson,
} from '@/util/lesson'
import {
  DataTableHeader,
} from 'vuetify'
import {
  VuetifySelectListItem,
} from '@/plugins/vuetify/types'
import cloneDeep from 'clone-deep'

export default Vue.extend({
  props: {
    lesson: {
      default: newLesson(),
      type: Object,
    } as PropValidator<Lesson>,
  },
  data: () => ({
    headers: [
      {
        value: `typeDataIds`,
        text: `Type Data IDs`,
        sortable: false,
      },
    ] as DataTableHeader[],
    lessonTypes: [
      {
        text: `Grammar`,
        value: `grammar`,
      },
      {
        text: `Vocabulary`,
        value: `vocabulary`,
      },
    ] as VuetifySelectListItem<LessonType>[],
    lesson_: newLesson(),
  }),
  mounted () {
    this.lesson_ = cloneDeep(this.lesson)
  },
  methods: {
    async moveLessonIdsMatrixUp (index: number) {
      const {
        typeDataIds,
      } = this.lesson
      const typeDataId = typeDataIds.splice(index, 1)[0]
      typeDataIds.splice(index - 1, 0, typeDataId)
    },
    async moveLessonIdsMatrixDown (index: number) {
      const {
        typeDataIds,
      } = this.lesson
      const typeDataId = typeDataIds.splice(index, 1)[0]
      typeDataIds.splice(index + 1, 0, typeDataId)
    },
  },
})
